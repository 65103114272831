import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vkontakte/vkui/dist/cssm/components/Typography/Typography.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vkontakte/vkui/dist/cssm/components/Typography/Title/Title.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vkontakte/vkui/dist/cssm/components/Typography/Subhead/Subhead.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/highlight.js/styles/github-dark.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vkontakte/vkui-tokens/themes/vkBase/cssVars/declarations/onlyVariablesLocalIncremental.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vkontakte/vkui-tokens/themes/vkBaseDark/cssVars/declarations/onlyVariablesLocalIncremental.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vkontakte/vkui/dist/cssm/styles/dynamicTokens.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vkontakte/vkui/dist/cssm/styles/constants.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vkontakte/vkui/dist/cssm/styles/common.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/client/ClientAnalytics/ClientAnalytics.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/client/ClientConfig/ClientConfig.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/client/ClientSearch/ClientSearch.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/shared/Header/Header.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/patch.css")